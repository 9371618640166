<template>
  <div class="content-container">
    <a-tabs type="card" @change="tabChange">
      <a-tab-pane key="1" tab="Данные поставщика">
        <ClientData />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ClientData from "@/components/provider/ProviderData"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "TabsInfo",
  async mounted() {
    await this.getSupplier(this.$route.params.id)
  },
  components: {
    ClientData,
  },
  computed: mapGetters({
    createForm: "suppliers/createForm",
    clientTypeId: "suppliers/clientTypeId",
  }),
  watch: {
    createForm() {
      //this.setCreateFormContacts()
    },
  },
  methods: {
    tabChange() {
      console.log("tab changed")
    },
    ...mapActions({
      getSupplier: "suppliers/getSupplier",
    }),
    ...mapMutations({
      setCreateForm: "suppliers/SET_CREATE_FORM",
    }),
  },
}
</script>

<style scoped></style>
