<template>
  <div>
    <TabsInfo class="tabsinfo" />
  </div>
</template>

<script>
import TabsInfo from "@/components/provider/TabsInfo"
export default {
  name: "ProviderInfo",
  components: {
    TabsInfo,
  },
}
</script>

<style scoped>
.tabsinfo {
  min-height: 570px;
}
.buttonsholder {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 auto;
}
</style>
